<template>
  <v-app full-height>
    <HeaderCheckout/>
    <div
        v-show="loading"
        class="absolute w-100 h-100 z-20 flex align-center bg-white"
    >
      <div class="w-full flex flex-column align-center justify-center ">
        <client-only>
          <CarLoader/>
        </client-only>
        <span class="title-h5">
          טוען עמוד...
        </span>

      </div>

    </div>
    <main v-show="!loading">
      <slot :key="route.fullPath"/>
    </main>
  </v-app>
</template>
<script setup lang="ts">
const nuxtApp = useNuxtApp();
const route = useRoute();
const loading = ref(false);

nuxtApp.hook("page:start", () => {

  loading.value = true;
  window.scrollTo({
    top: 100,
    left: 100,
    behavior: "instant",
  });

});
nuxtApp.hook("page:finish", () => {

  loading.value = false;
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "instant",
  });
});
onMounted(() => {
  // prevents window to scrollTop then returns to savedPosition
  window.history.scrollRestoration = 'auto'

  // but restore manual scrollRestoration at the first navigation
  const unwatch = useRouter().beforeEach(() => {
    window.history.scrollRestoration = 'manual'
    unwatch()
  })

  // and restore auto scrollRestoration when leaving the page
  window.addEventListener('unload', () => {
    window.history.scrollRestoration = 'auto'
  })
})
</script>
